const kr = {
  translations: {
    dialogs: {
      review: {
        require: {
          content: '소감/후기를 입력해주세요.',
          comment: '코멘트를 입력해주세요.'
        }
      },
      category: {
        require: {
          classification: '분류를 선택해주세요.',
          title: '제목을 입력해주세요.'
        }
      },
      notice: {
        require: {
          title: '제목을 입력해주세요.',
          content: '내용을 입력해주세요.'
        }
      },
      booth: {
        require: {
          boothType: '부스 유형을 선택해 주세요.',
          mediaType: '미디어 유형을 선택해 주세요.',
          order: '(숫자) 부스 위치를 입력해 주세요.',
          positiveOrder: '1이상 입력해 주세요.'
        }
      },
      diary: {
        require: {
          content: '활동일지를 입력해주세요.',
          feelings: '느낀점을 입력해주세요.',
          comment: '코멘트를 입력해주세요.'
        }
      },
      student: {
        require: {
          studentID: '아이디를 입력해주세요.',
          teacherID: '선생님을 선택해주세요',
          approvedLevel: '레벨을 선택해주세요.',
          category: '카테고리를 선택해주세요.'
        }
      },
      teacher: {
        require: {
          category: '카테고리를 선택해주세요.',
          teacherID: '아이디를 입력해주세요.'
        }
      },
      selfplan: {
        require: {
          goal: '목표를 입력해주세요.',
          comment: '코멘트를 입력해주세요.',
          detailedPlan: '계획를 입력해주세요.'
        }
      },
      network: {
        require: {
          organizationName: '기관명을 입력해주세요.'
        }
      },
      calendar: {
        require: {
          category: '카테고리를 선택해주세요.',
          title: '제목을 입력해주세요.',
          description: '내용을 입력해주세요.',
          totalCompletionTime: '(숫자) 총 이수시간을 입력해주세요.',
          cronDaysOfWeek: '근무요일을 선택해 주세요.',
          end: '종료날짜를 선택해주세요',
          start: '시작날짜를 선택해주세요',
          mentor: '멘토를 선택해주세요',
          students: '참여 청소년을 선택해주세요'
        }
      },
      survey: {
        require: {
          category: '카테고리를 선택해 주세요.',
          title: '제목을 입력해 주세요.',
          startAt: '시작일을 선택해 주세요.',
          endAt: '종료일을 선택해 주세요.',
          index: '질문 인덱스를 입력해 주세요.',
          question: '질문 내용을 입력해 주세요.'
        }
      }
    },
    helmet: {
      login: '관리자 | 로그인',
      user: { settings: '관리자 | 계정' },
      inflow: '관리자 | 유입통계',
      mentor: '관리자 | 멘토',
      student: '관리자 | 청소년',
      selfPlan: '관리자 | 자립계획',
      schedule: '관리자 | 일정',
      review: '관리자 | 소감/후기',
      activity: '관리자 | 활동일지',
      notice: '관리자 | 공지사항',
      survey: '관리자 | 설문',
      pageNotFound: '관리자 | 페이지를 찾을수 없음',
      internalServerError: '관리자 | 서버 에러'
    },
    snackbar: {
      profileSuccess: '변경 완료',
      changePasswordSuccess: '비밀번호 변경 완료. 다시 로그인해 주세요.',
      createSuccess: '추가 완료',
      createFail: '추가 실패',
      updateSuccess: '수정 완료',
      updateFail: '수정 실패',
      deleteSuccess: '삭제 완료',
      deleteFail: '삭제 실패',
      createKpiSuccess: '링크 생성 완료',
      createTokenSuccess: '토큰 생성 완료'
    },
    upload: {
      remove: '제거'
    },
    search: {
      table: {
        toolbar: {
          search: '이름으로 검색',
          notFound: '내용이 없습니다.',
          detail: '내용을 추가하거나 검색 단어를 확인해 주세요.'
        },
        notExist: {
          notFound: '내역이 존재하지 않습니다.'
        }
      }
    },
    tableHeader: {
      kpi: {
        copyToClipboard: 'ID 복사',
        domainName: '사이트명',
        url: '사이트 주소',
        count: '누적수',
        createdAt: '생성일',
        delete: '삭제'
      },
      token: {
        copyToClipboard: '복사',
        expires: '유효',
        tokenName: '토큰명',
        token: '토큰',
        scope: '유효범위',
        createdAt: '생성일',
        delete: '삭제'
      }
    },
    login: {
      welcome: '일하루',
      primary: '로그인',
      remember: '기억하기',
      forgotPassword: '비밀번호 찾기',
      submit: '로그인',
      label: {
        id: '아이디를 입력해 주세요',
        password: '비밀번호를 입력해 주세요'
      },
      valid: {
        id: '유효한 아이디를 입력해 주세요'
      },
      require: {
        id: '아이디를 입력해 주세요',
        password: '비밀번호를 입력해 주세요'
      }
    },
    user: {
      account: {
        header: {
          title: '계정',
          name1: '관리',
          name2: '계정 설정'
        },
        tabs: {
          general: '프로필',
          changePassword: '비밀번호 변경',
          kpi: 'KPI',
          token: '토큰 관리'
        },
        general: {
          label: {
            id: '아이디',
            email: '이메일',
            companyRegistrationNumber: '사업자 등록번호',
            address: '주소'
          },
          require: {
            name: '이름을 입력해 주세요.'
          }
        },
        changePassword: {
          minimum: '최소 6자 이상 입력해 주세요.',
          label: {
            old: '현재 비밀번호',
            new: '변경 비밀번호',
            confirm: '변경 비밀번호 확인'
          },
          require: {
            old: '현재 패스워드를 입력해 주세요.',
            new: '변경 패스워드를 입력해 주세요.',
            confirm: '비밀번호 확인을 입력해 주세요.'
          },
          match: {
            confirm: '비밀번호가 일치하지 않습니다.'
          }
        },
        kpi: {
          label: {
            domainName: '사이트명 (ex, 주식회사 소금광산)',
            url: '사이트 주소 (ex, https://saltmine.kr)'
          },
          require: {
            domainName: '사이트 용도를 입력해 주세요.',
            url: '사이트 주소를 입력해 주세요.'
          },
          checkbox: {
            kpi: 'KPI',
            smti: 'SMTi'
          },
          submit: '토큰 생성'
        },
        token: {
          label: {
            tokenName: '토큰명(최대 12자)'
          },
          require: {
            tokenName: '토큰명을 입력해 주세요.',
            expiresIn: '만료기간을 선택해 주세요.'
          },
          checkbox: {
            kpi: 'KPI',
            smti: 'SMTi'
          },
          submit: '토큰 생성'
        },
        saveChanges: '변경사항 저장'
      }
    },
    kpi: {
      header: {
        title: 'KPI',
        name1: 'KPI 목록'
      }
    },
    sideDashboard: {
      role: '관리자',
      homepage: {
        title: '사이트',
        user: {
          title: '관리',
          account: '계정 설정'
        },
        kpi: {
          title: 'KPI'
        },
        inflow: {
          title: '유입통계'
        },
        useManagement: {
          title: '사용자 관리'
        },
        scheduleManagement: {
          title: '일정 관리'
        },
        reviewAndActivityHistory: {
          title: '리뷰/활동내역'
        },
        surveySatisfaction: {
          title: '설문/만족도조사'
        },
        settings: {
          title: '설정'
        }
      },
      fox: {
        title: '서비스 관리'
      }
    },
    navDashboard: {
      langs: {
        kr: '한국어',
        en: '영어'
      },
      account: {
        settings: '설정',
        logout: '로그아웃'
      }
    },
    // 404 에러
    pageNotFound: {
      primary: '페이지가 존재하지 않습니다.',
      secondary:
        '죄송합니다. 찾고 있는 페이지를 찾을 수 없습니다. URL을 잘못 입력했나요? 맞춤법을 반드시 확인하세요.',
      goToHome: '홈으로'
    },
    // 500 에러
    internalServerError: {
      primary: '500 내부 서버 에러가 발생했습니다.',
      secondary: '오류가 발생했습니다. 나중에 다시 시도해 주세요.',
      goToHome: '홈으로'
    }
  }
};

export default kr;
