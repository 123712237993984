function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  inflow: {
    root: path(ROOTS_DASHBOARD, '/inflow')
  },
  mentor: {
    root: path(ROOTS_DASHBOARD, '/mentor')
  },
  student: {
    root: path(ROOTS_DASHBOARD, '/student')
  },
  calendar: {
    root: path(ROOTS_DASHBOARD, '/calendar')
  },
  review: {
    root: path(ROOTS_DASHBOARD, '/review')
  },
  activity: {
    root: path(ROOTS_DASHBOARD, '/activity')
  },
  survey: {
    root: path(ROOTS_DASHBOARD, '/survey')
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings')
  },
  notice: {
    root: path(ROOTS_DASHBOARD, '/notice')
  },
  selfPlan: {
    root: path(ROOTS_DASHBOARD, '/self-plan')
  }
};
