import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { path: '/', element: <Navigate to="/login" replace /> },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/',
          element: <Navigate to="/dashboard/inflow" replace />
        },
        {
          path: 'mentor',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard roles={[0, 1]}>
                  <Mentor />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'student',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard roles={[0, 1]}>
                  <Student />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'user',
          children: [
            {
              path: 'account',
              element: (
                <RoleBasedGuard roles={[0, 1, 2]}>
                  <Account />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'inflow',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard roles={[0, 1]}>
                  <Analytics />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'calendar',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard roles={[0, 1, 2]}>
                  <Schedule />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'review',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard roles={[0, 1, 2]}>
                  <Review />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'activity',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard roles={[0, 1, 2]}>
                  <Activity />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'survey',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard roles={[0, 1]}>
                  <Survey />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'settings',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard roles={[0, 1]}>
                  <Settings />
                </RoleBasedGuard>
              )
            },
            {
              path: '/booth',
              element: (
                <RoleBasedGuard roles={[0, 1]}>
                  <Settings />
                </RoleBasedGuard>
              )
            },
            {
              path: '/network',
              element: (
                <RoleBasedGuard roles={[0, 1]}>
                  <Settings />
                </RoleBasedGuard>
              )
            },
            {
              path: '/category',
              element: (
                <RoleBasedGuard roles={[0, 1]}>
                  <Settings />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'notice',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard roles={[0, 1]}>
                  <Notice />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'self-plan',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard roles={[0, 1, 2]}>
                  <SelfPlan />
                </RoleBasedGuard>
              )
            }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

/* IMPORT COMPONENTS */
// Authentication
const Login = Loadable(lazy(() => import('src/pages/authentication/Login')));
const ResetPassword = Loadable(lazy(() => import('src/pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('src/pages/authentication/VerifyCode')));
// Dashboard
const Account = Loadable(lazy(() => import('src/pages/dashboard/Account')));
const Mentor = Loadable(lazy(() => import('src/pages/dashboard/Mentor')));
const Student = Loadable(lazy(() => import('src/pages/dashboard/Student')));
const Analytics = Loadable(lazy(() => import('src/pages/dashboard/Analytics')));
const SelfPlan = Loadable(lazy(() => import('src/pages/dashboard/SelfPlan')));
const Schedule = Loadable(lazy(() => import('src/pages/dashboard/Schedule')));
const Review = Loadable(lazy(() => import('src/pages/dashboard/Review')));
const Activity = Loadable(lazy(() => import('src/pages/dashboard/Activity')));
const Survey = Loadable(lazy(() => import('src/pages/dashboard/Survey')));
const Notice = Loadable(lazy(() => import('src/pages/dashboard/Notice')));
const Settings = Loadable(lazy(() => import('src/pages/dashboard/Settings')));
// Main
const Page500 = Loadable(lazy(() => import('src/pages/Page500')));
const Page404 = Loadable(lazy(() => import('src/pages/Page404')));
